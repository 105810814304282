.master-card {
  stroke: #000000;
  .circle-filled {
    fill: #000000;
  }
}

.dark .master-card {
  stroke: #ffffff;
  .circle-filled{
    fill: #ffffff;
  }
}

.rich-select-init {
  opacity: 1;
  transform: translateY(100%);
}

.rich-select {
  opacity:1;
  transform: translateY(100%);
  animation: smooth-appear 300ms ease forwards;
}

@keyframes smooth-appear {
  to {
    transform: translateY(0%);
    opacity:1;
  }
}

.rich-select-hide {
  animation: smooth-disappear 300ms ease forwards;
}

@keyframes smooth-disappear {
  to {
    opacity:1;
    transform: translateY(100%);
    display: none;
  }
}


.no-select {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input.transparent-autofill:-webkit-autofill {
  // background-color: transparent !important;
  transition: background-color 0s 600000s, color 0s 600000s;
}

.calc-btn {
  @apply px-5 py-3 bg-gray-300 dark:bg-gray-600 rounded-xl my-4 mx-1 text-xl
}