@tailwind components;
@tailwind utilities;

@layer components {
  .bar-negative {
    @apply border dark:border-gray-200 border-gray-800
  }
}

#monthSelectorWrap::-webkit-scrollbar {
  display: none;
}

.bar-chart-bar {
  height: 12px;
  margin: 3px 0;
  border-radius: 10px;
}

.horiz-bar-chart-bar {
  width: 12px;
  margin: 0 1px;
  border-radius: 30px;
}

.cat-label {
  white-space: nowrap;
}