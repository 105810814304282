@import "assets/fonts/inter/inter.css";
@import "assets/fonts/lineawesome/css/line-awesome.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --yellow: #36a4b9;
    --grey:#888888;
    --dark-grey: #cfcfcf;
    --light-grey: #dfdfdf;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #555555;
}

::-webkit-scrollbar-thumb {
    background-color: #999999;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
    font-feature-settings: 'case', 'calt', 'tnum';
}

code, .code {
  font-family: 'Inconsolata', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
    .btn-primary {
        @apply bg-primary hover:bg-primary-dark py-2 px-8 text-white text-sm rounded-full border-0;
    }
}

.text-outline {
    text-shadow:
            0 0 1px #aaaaaa,
            -1px -1px 1px #aaaaaa,
            -1px 1px 1px #aaaaaa,
            1px 1px 1px #aaaaaa,
            1px -1px 1px #aaaaaa;
    position: relative;
}