@tailwind components;

@layer components {
  .btn-play {
    @apply bg-cyan-300 dark:bg-cyan-500 py-4 px-5 m-2 rounded-full
  }
  .btn-controls {
    @apply bg-gray-300 dark:bg-gray-600 py-3 px-4 m-2 rounded-full
  }
  .btn-controls-secondary {
    @apply py-2 px-1 m-2 rounded-full text-xl
  }
}