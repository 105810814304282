label{
  @apply text-xs
}
.input-box{
  @apply p-2 rounded-lg w-full border-2 dark:border-gray-500 focus:border-primary hover:border-gray-400 outline-none dark:bg-gray-700
}

.check-group {
  display: block;
  //overflow: hidden;

  label {
    display: inline-block;
    cursor: pointer;
    border: solid 2px var(--light-grey);
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: center;

    &:hover{
      border: solid 2px var(--yellow);
    }
  }

  label {
    @apply dark:border-gray-500 text-gray-800 dark:text-gray-100
  }

  label:hover {
    @apply border-primary bg-gray-100 dark:bg-gray-600
  }

  input[type=checkbox], input[type=radio] {
    width: auto;
    position: absolute;
    visibility: hidden;
    display: none;

    &:checked+label {
      color: #ffffff;
      background: var(--yellow);
      border: solid 2px var(--yellow);
    }
  }
}

.check-btn {
  @apply pl-2 pr-4 py-1 rounded-full border border-gray-600 flex items-center leading-none cursor-pointer
}

.check-btn.checked {
  @apply bg-primary
}

.check-btn-circle {
  @apply bg-gray-800 inline-block border border-gray-600 w-3 h-3 mr-3 rounded-full
}

.checked .check-btn-circle {
  @apply bg-white border-0
}